import { Inject, Injectable, InjectionToken, OnDestroy } from "@angular/core";
import { NavigationStart, ResolveEnd, Router } from "@angular/router";
import { Platform } from "@angular/cdk/platform";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";
import * as _ from "lodash";
import { AuthService } from "../../app/main/services/auth.service";
import { SocketService } from "app/main/services/socket.service";

// Create the injection token for the custom settings
export const FUSE_CONFIG = new InjectionToken("fuseCustomConfig");

@Injectable({
  providedIn: "root",
})
export class FuseConfigService implements OnDestroy {
  // Private
  private _configSubject: BehaviorSubject<any>;
  private readonly _defaultConfig: any;
  private _isLogin: boolean = false;
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {Platform} _platform
   * @param {Router} _router
   * @param _config
   */
  constructor(
    private _platform: Platform,
    private _router: Router,
    private _auth: AuthService,
    private _socket: SocketService,
    @Inject(FUSE_CONFIG) private _config
  ) {
    this._unsubscribeAll = new Subject();

    this._socket.onResetConfigChanged
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((_reset) => {
        this._isLogin = _reset;
      });

    // Set the default config from the user provided config (from forRoot)
    this._defaultConfig = _config;

    // Initialize the service
    this._init();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next("");
    this._unsubscribeAll.complete();
  }
  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Set and get the config
   */
  set config(value) {
    // Get the value from the behavior subject
    let config = this._configSubject.getValue();

    // Merge the new config
    config = _.merge({}, config, value);

    // Notify the observers
    this._configSubject.next(config);
  }

  get config(): any | Observable<any> {
    return this._configSubject.asObservable();
  }

  /**
   * Get default config
   *
   * @returns {any}
   */
  get defaultConfig(): any {
    return this._defaultConfig;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Initialize
   *
   * @private
   */
  private _init(): void {
    /**
     * Disable custom scrollbars if browser is mobile
     */
    if (this._platform.ANDROID || this._platform.IOS) {
      this._defaultConfig.customScrollbars = false;
    }

    // Set the config from the default config
    this._configSubject = new BehaviorSubject(_.cloneDeep(this._defaultConfig));

    // Reload the default layout config on every RoutesRecognized event
    // if the current layout config is different from the default one
    this._router.events
      .pipe(filter((event) => event instanceof ResolveEnd))
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        if (
          !_.isEqual(
            this._configSubject.getValue().layout,
            this._defaultConfig.layout
          )
        ) {
          // Clone the current config
          const config = _.cloneDeep(this._configSubject.getValue());

          // Reset the layout from the default config
          config.layout = _.cloneDeep(this._defaultConfig.layout);

          this._auth.getSettings().then((cfg: any) => {
            if (cfg.ok !== undefined) {
              cfg = [];
            }
            const _folded: any = cfg.filter((c: any) => {
              return c.code === "hidden-sidebar";
            });
            if (_folded.length > 0) {
              config.layout.navbar.folded =
                _folded[0].value === "false" ? false : true;
            }

            if (this._isLogin) {
              config.layout.navbar.hidden = true;
              config.layout.toolbar.hidden = true;
              config.layout.footer.hidden = true;
              config.layout.sidepanel.hidden = true;
            }
            // Set the config
            this._configSubject.next(config);
          });
        }
      });

    this._router.events
      .pipe(filter((event) => event instanceof NavigationStart))
      .subscribe(async (r: any) => {
        this._auth.isDefault = false;
        let settings: any = (await this._auth.getSettings()) || [];
        if (!Array.isArray(settings)) {
          settings = [];
        }
        const def: any = settings.filter((setting: any) => {
          return setting.code === "default-feature";
        });

        // if (r.url.includes('embeed')) {
        //     const id = r.url.split('/')[r.url.split('/').length-1];
        //     if(def.length > 0) {
        //         if (def[0].value === id) {
        //             this._auth.defaultFeature = id;
        //             this._auth.isDefault = true;
        //         }
        //     }
        // } else {
        //     this._auth.getFeatureIdByRoute(r.url).then( (info: any) => {
        //         if (!info.data.response === undefined) {
        //             if (def.length !== 0) {
        //                 if (def[0].value === info.data.response.feature_id) {
        //                     this._auth.defaultFeature = info.data.response.feature_id;
        //                     this._auth.isDefault = true;
        //                 }
        //             }
        //         }
        //     })

        // }
      });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Set config
   *
   * @param value
   * @param {{emitEvent: boolean}} opts
   */
  setConfig(value, opts = { emitEvent: true, isLogin: false }): void {
    // Get the value from the behavior subject
    let config = this._configSubject.getValue();

    // Merge the new config
    config = _.merge({}, config, value);

    if (opts.isLogin) {
      this._isLogin = opts.isLogin;
    }

    // If emitEvent option is true...
    if (opts.emitEvent === true) {
      // Notify the observers
      this._configSubject.next(config);
    }
  }

  /**
   * Get config
   *
   * @returns {Observable<any>}
   */
  getConfig(): Observable<any> {
    return this._configSubject.asObservable();
  }

  /**
   * Reset to the default config
   */
  resetToDefaults(): void {
    // Set the config from the default config
    this._configSubject.next(_.cloneDeep(this._defaultConfig));
  }
}
