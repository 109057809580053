export const environment = {
    production: false,
    hmr       : false,
    // api_server: 'http://192.168.2.87:2040/',
    api_server: 'https://api.forms.itesic.cl/',
    system_identifier: '2A721AB7-224F-4928-8AFB-B674FF823DA7',
    allowImageCompressor: true,
    modules: {
        forms: {
            endpoint: 'modules/forms/reports',
            inputEndpoint: 'modules/forms/imports',
            urlGenerateShortcutTemplates: '/modules/forms/pages/frms/shortcut/',
            urlGenerateShortcutList: '/modules/forms/lists/'
        }
    },
    auth: {
        clientId: 'b3787e36-d360-4ce2-9866-32e0f18e598e',
        redirectUri: 'http://localhost:4200'
    },
    mapbox: {
        token: 'pk.eyJ1IjoidnJ1YmlsYXIiLCJhIjoiY2xzMGhpNGJqMDB1bDJqbnF6c3NldjFrNyJ9.HSOBbHQ1fxE7MPA1P4wIDw'
    }
};

