import { UserSocket } from "./../classes/user";
import {
  Injectable,
  Output,
  EventEmitter,
  OnDestroy,
  OnInit,
} from "@angular/core";
// import { Socket } from "ngx-socket-io";
import { io, Socket } from 'socket.io-client';

import { Router } from "@angular/router";
import { UiService } from "./ui.service";
import { Subject, BehaviorSubject, fromEvent } from 'rxjs';
import { environment } from "environments/environment";

@Injectable({
  providedIn: "root",
})
export class SocketService implements OnInit, OnDestroy {
  public socketStatus = false;
  private socket: Socket;
  @Output() conectado = new EventEmitter<boolean>();
  notifier = new Subject();
  public user: UserSocket = null;
  onSocketChanged: BehaviorSubject<boolean>;
  @Output() resetConfigForNewLogin = new EventEmitter<boolean>();
  onResetConfigChanged: BehaviorSubject<boolean>;
  constructor(
    // private socket: Socket,
    private router: Router,
    private ui: UiService
  ) {
    this.onSocketChanged = new BehaviorSubject(false);
    this.onResetConfigChanged = new BehaviorSubject(false);
    this.socket = io(environment.api_server, {
      withCredentials: true,
      transports: ['websocket', 'polling']
    });
    this.checkStatus();
  }

  // tslint:disable-next-line: contextual-lifecycle
  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.notifier.next("");
    this.notifier.unsubscribe();
  }

  checkStatus(): void {
    this.socket.on("connect", () => {
      this.socketStatus = true;
      this.conectado.emit(true);
      this.onSocketChanged.next(true);
      this.loadStorage();
    });

    this.socket.on("disconnect", () => {
      this.conectado.emit(false);
      this.onSocketChanged.next(false);
      this.socketStatus = false;
    });
  }

  emit(evento: string, payload?: any, callback?: Function): void {
    this.socket.emit(evento, payload, callback);
  }

  listen(evento: string) {
    return fromEvent(this.socket, evento);
  }

  async saveStorage() {
    await localStorage.setItem("user", JSON.stringify(this.user));
    this.user = await JSON.parse(localStorage.getItem("user"));
  }

  loadStorage(): void {
    if (localStorage.getItem("user")) {
      this.user = JSON.parse(localStorage.getItem("user"));
      this.loginWs(this.user.name, this.user.login || "");
    }
  }

  loginWs(name: string, login?: string): Promise<any> {
  return new Promise((resolve, reject) => {
      this.emit("configure-name", { name, login }, (resp: any) => {
        this.user = new UserSocket(name, login, resp.id);
        this.saveStorage();
        resolve(true);
      });
    });
  }

  getPendingMessages() {
    return this.listen("pending-messages");
  }

  getDeleteMessage() {
    return this.listen("delete-message");
  }

    logoutWs(): void {
    this.user = null;
    localStorage.removeItem("user");
    const payload = {
      name: "no-name",
      login: "",
    };
    this.sendReset(true);
    this.emit("configure-name", payload, () => {});
    console.log('paso aca por logoutWs');
    this.router.navigate(["/login"], { replaceUrl: true });
  }

  getUser(): any {
    return this.user;
  }

  sendReset(value: boolean) {
    this.resetConfigForNewLogin.emit(value);
    this.onResetConfigChanged.next(value);
  }

  public getPrivileges() {
    return this.listen("privileges");
  }
}
