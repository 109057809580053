import { Options, Componente } from './../interfaces/interfaces';
import { environment } from './../../../environments/environment';
import { Injectable, EventEmitter, Output, OnInit, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, Subscription, of } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { UiService } from './ui.service';
import { DataService } from './data.service';
import { Favorites, Features } from '../models/security.model';
import { SocketService } from './socket.service';
import { Router } from '@angular/router';
import { about as info } from "./../../about/about"
import { MsalService } from '@azure/msal-angular';
import * as forge from 'node-forge';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnInit, OnDestroy  {

  public token;
  public user: any = {};
  public userImageUrl = '';
  public rememberSession = false;
  public notificationsEnabled = false;
  public notificationSubscriptionHash: '';
  public appOptions: Options[] = [];
  notifier = new Subject();
  public isSocketConnected: boolean;
  public connectSubs: Subscription;
  public changePasswordRequired = false;
  public authType: string = 'application';
  public msAccountId: string = '';
  favorites: Favorites[];
  features: Features[];

  sidebarToggle: boolean = true;

  userSettings: any = [];

  defaultFeature: string = '';
  isDefault: boolean = false;
  aboutInfo: any = {};
  activeSession

  @Output() menu = new EventEmitter<any>();
  
  onLogout: BehaviorSubject<any>;


  fuseConfig: any;
  private _unsubscribeAll: Subject<any>;
  private _onUserSettingsChanged: BehaviorSubject<any>;


  constructor( private httpClient: HttpClient,
               private router: Router,
               private ds: DataService,
               public ws: SocketService,
               public ui: UiService,
               private msalService: MsalService
               ) { 

                this._unsubscribeAll = new Subject();
                this._onUserSettingsChanged = new BehaviorSubject(null);
                this.onLogout = new BehaviorSubject(false);
                this.loadAboutInfo();

               }
      
   ngOnInit(): void {
      

   }

   ngOnDestroy(): void
   {
       // Unsubscribe from all subscriptions
       this._unsubscribeAll.next();
       this._unsubscribeAll.complete();
   }

   get onUserSettingsChanged(): Observable<any>
  {
      return this._onUserSettingsChanged.asObservable();
  }

  loadAboutInfo = async () => {
    this.aboutInfo = await info.data;
  };

  login(data: { email: string, password: string, domain: string, microsoftUniqueId: string, microsoftAccessToken: string}): Promise<any> {
    return new Promise( async (resolve, reject) => {

      // // const credentials = `${data.email}:${data.password}`;


      // // const utf8Credentials = new TextEncoder().encode(credentials);
      // // const base64Credentials = btoa(String.fromCharCode(...utf8Credentials));

      // const headers = new HttpHeaders(
      //   { 'system_id': environment.system_identifier,
      //     'Content-Type': 'application/json',
      //     'Authorization': 'Basic ' + window.btoa(`${data.email}:${data.password}`)        }
      //   );

      // // const headers = new HttpHeaders(
      // //   { 'system_id': environment.system_identifier,
      // //     'Content-Type': 'application/json',
      // //     'Authorization': `Basic ${base64Credentials}`,
      // //     'AzureAccessToken': data.microsoftAccessToken        
      // //   });

      // // const body: any = { domain: data.domain };
      // // this.httpClient.post(`${environment.api_server }access/login`, body, { headers } ).subscribe( (response: any) => {
      // //     resolve(response);
      // // }, reject);


      try {
        // Obtén la clave pública del backend
        const key: any = await this.getPublicKey();
        const sep: string = '~|~'

        console.log(key);
        console.log(`${data.email}:${data.password}:${data.domain}:${data.microsoftAccessToken}:${environment.system_identifier}`);

        // Cifra las credenciales
        const encryptedCredentials = this.encryptCredentials(
          key,
          `${data.email}${sep}${data.password}${sep}${data.domain}${sep}${data.microsoftAccessToken}${sep}${environment.system_identifier}`
        );

        console.log('encryptedCredentials = ', encryptedCredentials);

        // Envía las credenciales cifradas al backend
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        const body = { keyId: JSON.parse(key).keyId, encryptedCredentials };

        console.log(body);

        this.httpClient
          .post(`${environment.api_server}access/login`, body, { headers, withCredentials: true })
          .subscribe(resolve, reject);
      } catch (error) {
        console.log(error);
        reject(error);
      }

    });
  }

  /**
   * Obtiene la clave pública del backend.
   */
  private getPublicKey(): Promise<string> {
    return this.httpClient
      .get(`${environment.api_server}keys/public-key`, { responseType: 'text' })
      .toPromise();
  }

  /**
   * Cifra las credenciales con la clave pública usando RSA.
   */
  private encryptCredentials(publicKeyPem: string, credentials: string): string {
    try {
      // Limpiar la clave pública eliminando saltos de línea y espacios innecesarios
   // Limpiar la clave pública
    const cleanedPublicKeyPem = publicKeyPem.replace(/\\r\\n/g, '\n').trim();
  
    // Convertir a PublicKey
    const publicKey = forge.pki.publicKeyFromPem(cleanedPublicKeyPem);
  
    // Cifrar las credenciales usando RSA-OAEP con SHA-256
    const encrypted = publicKey.encrypt(credentials, 'RSA-OAEP', {
      md: forge.md.sha256.create(), // Hash SHA-256
    });
  
    return forge.util.encode64(encrypted); // Codificar en Base64
    } catch (error) {
      console.error('Error al cifrar credenciales:', error);
      throw new Error('Fallo en el cifrado de credenciales.');
    }
  }

 


  getAuthType() {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders(
        { 'system_id': environment.system_identifier,
          'Content-Type': 'application/json'
        }
        );
      this.httpClient.get(`${environment.api_server }config/get-auth-type`, { headers } ).subscribe( (response: any) => {
          this.authType = response.type || 'application';
          resolve(response);
      }, reject);

    });
  }

  setLanguage(lang: any) {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'auth-token': this.token,
        'system_id': environment.system_identifier,
        'Content-Type': 'application/json'
      }); 

      const body = {
        lang: lang
      };

      this.httpClient.post(`${environment.api_server }config/setLanguage`, body, { headers } )
      .subscribe( (response: any) => {
          resolve(response);
      }, (reject: any) => {
        resolve(undefined);
      });

    });
  }


  // isSessionActive(): Observable<{ ok: boolean; message: string; userId?: string }> {
  //   return this.httpClient.get<{ ok: boolean; message: string; }>(`${environment.api_server }access/session-status`, { withCredentials: true });
  // }

  isSessionActive(): Observable<any> {
    return this.httpClient.get(`${environment.api_server}access/session-status`, { withCredentials: true });
}


  getSettings(): Promise<any> {
    return new Promise((resolve, reject) => {
        // Verificar si la sesión está activa
        this.isSessionActive()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
                next: (response) => {
                  console.log(response);
                    if (!response.ok) {
                        console.warn(response.message);
                        resolve([]); // Resolución vacía si no hay sesión activa
                    } else {
                        // Solo se ejecuta si la sesión es activa
                        const headers = new HttpHeaders({
                            'auth-token': this.token,
                            'system_id': environment.system_identifier,
                            'Content-Type': 'application/json',
                        });

                        this.httpClient
                            .get(`${environment.api_server}user/settings`, { headers })
                            .pipe(takeUntil(this._unsubscribeAll))
                            .subscribe(
                                (response: any) => {
                                    this.userSettings = response;
                                    this._onUserSettingsChanged.next(response);
                                    resolve(response || []);
                                },
                                (error) => {
                                    resolve([]); // Resolver con arreglo vacío en caso de error
                                }
                            );
                    }
                },
                error: (error) => {
                    console.error('Error al verificar la sesión:', error);
                    // this.router.navigate(['/login']); // Redirige al login en caso de error
                    resolve([]); // Resolución vacía en caso de error
                },
            });
    });
}


  // getSettings(): Promise<any> {
  //   return new Promise((resolve, reject) => {

  //     this.isSessionActive().pipe(takeUntil(this._unsubscribeAll)).subscribe({
  //       next: (response) => {
  //         if (!response.ok) {
  //           console.warn(response.message);
  //           this.router.navigate(['/login']); // Redirige al login si no hay sesión activa
  //         } else {
  //           console.log('Sesión activa para el usuario:', response.userId);
  //         }
  //       },
  //       error: (error) => {
  //         console.error('Error al verificar la sesión:', error);
  //         this.router.navigate(['/login']); // Redirige en caso de error
  //       },
  //     });

  //       const headers = new HttpHeaders({
  //         'auth-token': this.token,
  //         'system_id': environment.system_identifier,
  //         'Content-Type': 'application/json'
  //       });   
  //       console.log('llego a settings');
  //       console.trace(); // Muestra el stack de llamadas
  //     this.httpClient.get(`${environment.api_server }user/settings`, { headers } )
  //     .pipe(takeUntil(this._unsubscribeAll))
  //     .subscribe( (response: any) => {
  //       console.log(response);
  //         this.userSettings = response;
  //         this._onUserSettingsChanged.next(response);
  //         resolve(response || []);
  //     }, (reject: any) => {
  //       resolve([])
  //     });

  //   });
  // }

  async closeSession() {
    if (this.authType === 'microsoft') {
      const d = await this.msalService.logoutRedirect({
        postLogoutRedirectUri: environment.auth.redirectUri
      });
    
    }
    await localStorage.removeItem('remember-session');
    await localStorage.removeItem('authToken');

    this.token = '';
    this.rememberSession = false;

    this.httpClient.post(`${environment.api_server }access/logout`, {})
    .pipe(takeUntil(this._unsubscribeAll))
    .subscribe(() => {
        console.log('Sesión cerrada.');
        this.ws.logoutWs();
    }, error => {
        console.error('Error al cerrar sesión:', error);
    });
    
  }

  sendReset(value: boolean) {
    this.ws.sendReset(value);
  }

  changePwdReset(data: any, token: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'system_id': environment.system_identifier,
        'auth-reset-token': token
      });
      this.httpClient.post(`${environment.api_server }access/change-pwd-reset`, data, { headers } ).subscribe( (response: any) => {
          resolve(response);
      }, reject);

    });
  }

  changePwd(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'system_id': environment.system_identifier,
        'auth-token': this.token
      });
      this.httpClient.post(`${environment.api_server }access/change-pwd`, data, { headers } ).subscribe( (response: any) => {
          resolve(response);
      }, reject);

    });
  }

  resetPassword(data: { email: string, username: string}): Promise<any> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({ system_id: environment.system_identifier });
      this.httpClient.post(`${environment.api_server }access/reset-pwd`, data, { headers } ).subscribe( (response: any) => {
          resolve(response);
      }, reject);

    });
  }

  async saveToken(token: string, remember: boolean = true) {
    // this.token = token;
    this.token = 'no-token';
    localStorage.setItem('authToken', this.token);
    if (remember) {
      this.saveRememberSession();
    }
  }

  async saveRememberSession() {
    this.rememberSession = true;
    await localStorage.setItem('remember-session', 'true');
  }

  async clearRememberSession() {
    this.rememberSession = false;
    await localStorage.setItem('remember-session', 'false');
  }

  getToken() {
    return new Promise( (resolve, reject) => {
      localStorage.get('authToken').then( async(token) => {
        this.token = await token;
        await resolve(token);
      });
    });
  }
  
  getNotificationsEnabled() {
    return new Promise( (resolve, reject) => {
      localStorage.get('notificationEnabled').then( async(notificationsEnabled) => {
        this.notificationsEnabled = await notificationsEnabled;
        await resolve(notificationsEnabled);
      });
    });
  }

  getNotificationSubscriptionHash() {
    return new Promise( (resolve, reject) => {
      localStorage.get('subscription').then( async(notificationSubscriptionHash) => {
        this.notificationSubscriptionHash = await notificationSubscriptionHash;
        await resolve(notificationSubscriptionHash);
      });
    });
  }

  getRememberSession() {
    return new Promise( (resolve, reject) => {
      localStorage.get('remember-session').then( (data) => {
        resolve(data);
      });
    });
  }

  private async loadStorage() {
    this.token = await localStorage.get('authToken') || this.token || null;
    this.rememberSession = await localStorage.get('remember-session') || this.rememberSession || false;
    this.notificationsEnabled = await localStorage.get('notificationEnabled') || this.notificationsEnabled || false;
    this.notificationSubscriptionHash = await localStorage.get('subscription') || this.notificationSubscriptionHash || '';
  }

  getMenuOpts() {
    return this.httpClient.get<Componente[]>('/assets/data/menu.json');
  }


  async loadToken() {
    this.token = await localStorage.getItem('authToken') || this.token || null;
  }

  // tslint:disable-next-line: adjacent-overload-signatures
  getToken2() {
    return new Promise<string>( async (resolve, reject) => {
      const token = await localStorage.getItem('authToken');
      resolve(token);
    });
    
  }

  async loadRememberSession() {
    return await localStorage.getItem('remember-session') || this.rememberSession || null;
  }

  async loadNotificationEnabled() {
    this.notificationsEnabled = localStorage.get('notificationEnabled') || this.notificationsEnabled || false;
  }


  async validateToken(): Promise<boolean> {
      try {
            this.token = '';
            // await this.loadToken();
            // if (this.token === null || undefined || '') {
            //   this.router.navigate(['/login'], { replaceUrl: true });
            //   return Promise.resolve(false);
            // }

            this.ws.getPrivileges().pipe( takeUntil( this.notifier)).subscribe( async (privileges: any) => {
              this.appOptions = privileges;
              this.ds.features = privileges;
              this.ds.getMainMenu(privileges);

            });

            // El getOptions debería llamar a otra ruta de la API para extrar el JSON formateado para esta App Angular
            // y registrar el menu como se hace en esta funcion  getMainMenu()
            this.ds.getOptions().then( ( obsData ) => {
              obsData.pipe(takeUntil(this.notifier)).subscribe( ( opts: any) => {
                this.ds.features = opts.data;
                this.appOptions = opts.data;
                this.ds.getMainMenu(opts);
              });
            });

            const url: string = environment.api_server + 'user';
            return new Promise<boolean>( resolve => {
              const headers = new HttpHeaders({
                'auth-token': this.token,
                'system_id': environment.system_identifier
              });
              this.httpClient.get(url, { headers })
              .pipe(takeUntil(this.notifier))
              .pipe(catchError( (error: any) => {
                return of({ ok: false, message: error.message || '' });
              }))
              .subscribe( async (response: any) => {
                if (response.ok === true) {
                  this.user = response.data.response;
                  this.userImageUrl = this.user.avatar;
                  resolve(true);
                } else {
                  // await localStorage.removeItem('authToken');
                  this.router.navigate(['/login'], { replaceUrl: true });
                  resolve(false);
                }
              });
            });
      } catch (error) {
        this.router.navigate(['/home'], { replaceUrl: true });
        return Promise.resolve(false);
      }
  }


  async validateResetToken(token: string): Promise<boolean> {

    const url: string = environment.api_server + 'access/change-pwd-reset';

    return new Promise<boolean>( resolve => {
      const headers = new HttpHeaders({
        'auth-reset-token': token,
        'system_id': environment.system_identifier
      });

      this.httpClient.get(url, { headers }).subscribe( async (response: any) => {
        if (response.ok === true) {
          this.user = response.data.response;
          if (environment.api_server.substr(environment.api_server.length - 1, 1).includes('/')) {
            this.userImageUrl = environment.api_server.substr(0, environment.api_server.length - 1 ) + this.user.user_avatar;
          } else {
            this.userImageUrl = environment.api_server + this.user.user_avatar;
          }
          resolve(true);
        } else {

          await localStorage.removeItem('authToken');

          // this.router.navigate(['/login'], { replaceUrl: true });

          resolve(false);
        }
      });
    });
  }
  
  getFavorites(): Promise<any> {
    return new Promise((resolve, reject) => {
      const url: string = environment.api_server + 'user/favorites';
      const headers = new HttpHeaders({
            'auth-token': this.token,
            'system_id': environment.system_identifier
      });
      this.httpClient.get(url, { headers }).subscribe((response: any) => {
      this.favorites = response.data;
      resolve(this.favorites);
                    }, reject);
        }
    );
  }  

  getFeatures(): Promise<any> {
    return new Promise((resolve, reject) => {
      const url: string = environment.api_server + 'user/features';
      const headers = new HttpHeaders({
            'auth-token': this.token,
            'system_id': environment.system_identifier
      });
      this.httpClient.get(url, { headers }).subscribe((response: any) => {
      this.features = response.data;
      resolve(this.features);
                    }, reject);
        }
    );
  }  

  countEmptyUrls(json) {
    if (Array.isArray(json)) {
      json.forEach((item) => this.countEmptyUrls(item));
    } else if (typeof json === 'object') {
      if (json.type === 'group' || json.type === 'collapsable') {
        let emptyUrls = 0;
        let totalItems = 0;
        json.children.forEach((child) => {
          if (child.url === '') {
            emptyUrls++;
          }
          totalItems++;
        });
        json.emptyUrls = emptyUrls;
        json.totalItems = totalItems;
      }
      Object.values(json).forEach((value) => this.countEmptyUrls(value));
    }
    return json;
  }
  
  

  getOneFeature(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const url: string = environment.api_server + 'user/feature/' + id;
      const headers = new HttpHeaders({
            'auth-token': this.token,
            'system_id': environment.system_identifier
      });
      this.httpClient.get(url, { headers }).subscribe((response: any) => {
      resolve(response);
                    }, reject);
        }
    );
  }

  getUrlEmbeededToken(): Promise<any> {
    return new Promise((resolve, reject) => {
      const url: string = environment.api_server + 'access/url-embeeded';
      const headers = new HttpHeaders({
            'auth-token': this.token,
            'system_id': environment.system_identifier
      });
      this.httpClient.post(url, { }, { headers }).subscribe((response: any) => {
        if (response.token) {
          resolve(response.token);
        } else {
          resolve(undefined);
        }
                    }, reject);
        }
    );
  }

  getFeatureIdByRoute(route: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const url: string = environment.api_server + 'user/feature-id-by-route';
      const headers = new HttpHeaders({
            'auth-token': this.token,
            'system_id': environment.system_identifier
      });
      const body: any = {
        route 
      };

      this.httpClient.post(url, body, { headers })
      .subscribe((response: any) => {
      resolve(response);
                    }, reject);
        }
    );
  }

  async redirectToDefaultFeature() {
    // await this.getSettings();
    if (this.userSettings===undefined) this.userSettings = [];
    this.userSettings = [];
    console.log('this.userSettings === ', this.userSettings);
    const defaultFeature: any = this.userSettings.filter( (setting: any) => {
      return (setting.code === 'default-feature') });
      try {
        if(defaultFeature.length > 0) {
          this.getOneFeature(defaultFeature[0].value).then( (resp: any ) => {
            if (resp.url === '') {
              this.router.navigate(['/apps/dashboards/main'], { replaceUrl: true });
              return;
            }
            this.router.navigate([resp.url], { replaceUrl: true });
          });
        } else {
          console.log('entro aca');
          this.router.navigate(['/apps/dashboards/main'], { replaceUrl: true });
        }
      } catch (error) {
          this.router.navigate(['/apps/dashboards/main'], { replaceUrl: true });
      }
      
  }


  updateUserSetting(code: string, value: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const url: string = environment.api_server + 'user/setting/update';
      const headers = new HttpHeaders({
            'auth-token': this.token,
            'system_id': environment.system_identifier
      });
      const body: any = {
            code,
            value
      }
      this.httpClient.post(url, body, { headers })
      .subscribe((response: any) => {
      resolve(response);
                    }, reject);
        }
    );
  }

 

  callCloseSession() {
    this.callCloseSessionConfirm();
  }

  async callCloseSessionConfirm() {
 
  }


}
