import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse,
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError, from } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from 'app/main/services/auth.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private router: Router, private _auth: AuthService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
          catchError((error: HttpErrorResponse | any) => {
            if (error.status === 401) {

                console.log(error);

                console.warn('Token inválido o expirado. Redirigiendo al login...');
                localStorage.removeItem('remember-session');
                localStorage.removeItem('authToken');
                this.router.navigate(["/login"], { replaceUrl: true });
            } else if (error.status === 403) {
                console.warn('Acceso denegado. Redirigiendo al login...');
                this.router.navigate(["/login"], { replaceUrl: true });
            }
            return throwError(error);
            // return throwError(() => error);
          })
        );
    }

    private async handleUnauthorizedError(): Promise<void> {
        localStorage.removeItem('remember-session');
        localStorage.removeItem('authToken');
        this.router.navigate(["/login"], { replaceUrl: true });
        await this.router.navigate(['/login']); // Redirige al login
    }
}
